import Api from "@/services/Api";

export default {
  index() {
    return Api().get(`bridges`);
  },
  get(id: string) {
    return Api().get(`bridges/${id}`);
  },
  getBridgeStatus(url: any) {
    return Api().get(url)
  },
  async getBridgeCrossing(url: any) {
    return Api().get(url)
  },
  async getSysCodes() {
    return Api().get('sys-codes')
  },
  async getSysComponents() {
    return Api().get('sys-components')
  },
  async getSysElements() {
    return Api().get('sys-elements')
  },
  async getProvinces(url: any) {
    return Api().get(url)
  },
  async getDistricts(url: any) {
    return Api().get(url)
  },
  async getInspections(id: any) {
    return Api().get(`bridges/${id}/inspections`);
  },
  async getInspectionSpans(id: any) {
    return Api().get(`inspections/${id}/inspection-spans`);
  },
  async getInspectionDetail(id: any) {
    return Api().get(`inspection-detail/${id}`);
  },
  async getElementsAll(id: any) {
    return Api().get(`inspection-spans/${id}/elements`);
  },
  async getElements(id: any) {
    return Api().get(`inspection-spans/${id}`);
  },

  async getBridgeElements(bridgeId: string) {
    return Api().get(`bridge-elements/${bridgeId}`);
  },

  async getAllElements(bridgeId: string) {
    return Api().get(`bridge-elements/`);
  },

  async getElementDamages(id: any) {
    return Api().get(`element-damages/${id}`);
  },
  getSoundnessSeries(scenario_number, bridge_id, span_number, component, element) {
    if (span_number) {
      if (component) {
        if (element) {
          return Api().get(`soundness/${scenario_number}/${bridge_id}/${span_number}/${component}/${element}`);
        }
        return Api().get(`soundness/${scenario_number}/${bridge_id}/${span_number}/${component}`);
      }
      return Api().get(`soundness/${scenario_number}/${bridge_id}/${span_number}`);
    }
    return Api().get(`soundness/${scenario_number}/${bridge_id}`);
  },

  getBridgeHistory(bridgeId: number) {
    return Api().get(`bridges/${bridgeId}/repair-histories`);
  },
  saveRepair(repair: any) {
    return Api().put(`repair-histories`, repair);
  },
  deleteRepair(id) {
    return Api().delete(`repair-histories/${id}`);
  },
  deleteResource(resource, id, custom) {
    const prefix = custom ? custom : 'static-data'
    if (resource === 'bridge') {
      return Api().delete(`bridge/${id}`)
    } else if (resource === 'inspection') {
      return Api().delete(`inspections/${id}`);
    } else {
      return Api().delete(`${prefix}/${resource}/${id}`);
    }
  },
  saveInspections(inspections) {
    return Api().put(`odk-inspections`, inspections);
  },
  getWorkList(scenario_number, bridge_id, span_number, component, element) {
    if (span_number) {
      if (component) {
        if (element) {
          return Api().get(`works/${scenario_number}/${bridge_id}/${span_number}/${component}/${element}`);
        }
        return Api().get(`works/${scenario_number}/${bridge_id}/${span_number}/${component}`);
      }
      return Api().get(`works/${scenario_number}/${bridge_id}/${span_number}`);
    }
    return Api().get(`works/${scenario_number}/${bridge_id}`);
  },
  saveAnalysis(analysis) {
    return Api().put(`analysis/`, analysis);
  },
  indexAnalysis() {
    return Api().get(`analysis/`);
  },
  indexSubmissions(form) {
    return Api().get(`odk/submissions/${form}`);
  },
  ingestSubmission(type, formId, id) {
    return Api().put(`odk/${type}/${formId}/${id}`);
  },
  getAnalysis(analysisId) {
    return Api().get(`analysis/${analysisId}`);
  },
  getScenarioAnalysis(analysisId) {
    return Api().get(`analysis/scenarios/${analysisId}`);
  },
  getConstrainedWork(analysisId) {
    return Api().get(`constrained-work/${analysisId}`);
  },
  saveYearlyBudget(payload, analysis_id) {
    return Api().put(`yearly-budget/${analysis_id}`, payload);
  },
  indexScenarios() {
    return Api().get(`sys-lcc-scenario/`);
  },
  saveBridge(bridge) {
    return Api().put(`bridge/`, bridge);
  },
  createBridge(bridge) {
    return Api().post(`bridge/`, bridge);
  },
  uploadMedia(type, parentId, payload) {
    return Api().post(`media/${type}/${parentId}`, payload);
  },
  uploadMediaMetadata(type, parentId, payload) {
    return Api().post(`media-metadata/${type}/${parentId}`, payload);
  },
  getBridgeSoudness(year) {
    return Api().get(`bridge-element/${year}`);
  },
  changeReviewStatus(formId: string, submissionId: string, reviewState: string) {
    return Api().put(`/review-status/${formId}/${submissionId}/${reviewState}`);
  },
  getReports(report) {
    return Api().get(`reports/`);
  },
  getAll() {
    return Api().get(`export/bridges`);
  },
  getDocumentation() {
    return Api().get(`documentation/`);
  },
  ingestLinks(data) {
    return Api().put(`ingestion/links`, data);
  },
};

