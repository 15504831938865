import CentralService from "@/services/CentralService";

const state = () => ({
  provincesList: [],
  districtList: [],
});

const getters = {
};

const actions = {

  async fetchProvinces({ commit }) {
    const response = await CentralService.get("provinces/");
    if (response) {
      commit("setProvinces", response.data);
    }
  },
  async fetchDistricts({ commit }) {
    const response = await CentralService.get("districts/");
    if (response) {
      commit("setDistricts", response.data);
    }
  },

};

const mutations = {
  setProvinces: (state, payload) => { state.provincesList = payload; },
  setDistricts: (state, payload) => { state.districtList = payload; },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};