<template>
  <span>
    <v-btn icon><v-icon color="blue" medium class="mr-2" @click="dialog = true">
      mdi-download
    </v-icon></v-btn>
    <div class="text-center">
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> {{  $t('export') }} </v-card-title>
        <v-card-text class="subtitle-1">
          {{  $t('choose-a-format-to-download') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">{{ $t("cancel") }}</v-btn>
          <v-btn color="blue darken-1" text @click="exportToExcel">Excel</v-btn>
          <v-btn color="blue darken-1" text @click="exportToCSV">CSV</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </span>

</template>


<script>
import * as XLSX from 'xlsx';

export default {
  name: "DataExport",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    data: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    fileName: {
      type: String,
      default: 'bms-export'
    },
    additionalTitles: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    exportToExcel() {
      const wb = XLSX.utils.book_new();
      const sheetName = this.fileName.length > 30 ? 'data' : this.fileName;
      wb.SheetNames.push(sheetName);
      const ws_data = [];

      this.additionalTitles.forEach(t => {
        ws_data.push(t);
      })

      ws_data.push(this.headers.map(h => h.text));

      this.data.forEach(i => {
        ws_data.push(this.headers.map(h => i[h.value]));
      });
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets[sheetName] = ws;

      XLSX.writeFile(wb, `${this.fileName}.xlsx`);
      this.dialog = false;
    },
    exportToCSV() {
      let csv_data = '';

      this.additionalTitles.forEach(t => {
        csv_data += t.join(',');
        csv_data += "\n";
      })

      csv_data += this.headers.map(h => h.text).join(',');
      csv_data += "\n";
      this.data.forEach(i => {
        csv_data += this.headers.map(h => i[h.value]).join(',');
        csv_data += "\n";
      })
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_data);
      anchor.target = '_blank';
      anchor.download = `${this.fileName}.csv`;
      anchor.click();
      this.dialog = false;

    },
  }
};
</script>
