
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueApexCharts from 'vue-apexcharts'
import VueCompositionAPI from '@vue/composition-api'
import '@mdi/font/css/materialdesignicons.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import "@fontsource/roboto";
import DataExport from "@/components/main/DataExport.vue";

Vue.config.productionTip = false;
Vue.use(VueApexCharts)
Vue.use(VueCompositionAPI)

Vue.component('apexchart', VueApexCharts)
Vue.component('DataExport', DataExport)

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
