import Vue from "vue";
import Vuex from "vuex";
import bridge from "./modules/bridge.js";
import auth from "./modules/auth.js";
import sysCode from "./modules/sysCode.js";
import adm from "./modules/adm.js";
import authentication from "./modules/authentication.js";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    bridge,
    sysCode,
    adm,
    authentication
  },
});

export default store;
