import BridgeService from "@/services/BridgeService";

const state = () => ({
  bridge_status_list: [],
  bridge_crossing_list: [],
  sysCodes: [],
  sysComponents: [],
  sysElements: []
});

const getters = {
};

const actions = {
  async fetchBridgeStatus({ commit }) {
    const response = await BridgeService.getBridgeStatus("sys-codes/bridge/bridge_status");
    if (response) {
      commit("setBridgeStatus", response.data);
    }
  },
  async fetchBridgeCrossing({ commit }) {
    const response = await BridgeService.getBridgeCrossing("sys-codes/bridge/bridge_crossing");
    if (response) {
      commit("setBridgeCrossing", response.data);
    }
  },
  async fetchSysCodes({ commit }) {
    const response = await BridgeService.getSysCodes();
    if (response) {
      commit("setSysCodes", response.data);
    }
  },
  async fetchSysComponents({ commit }) {
    const response = await BridgeService.getSysComponents();
    if (response) {
      commit("setSysComponents", response.data);
    }
  },
  async fetchSysElements({ commit }) {
    const response = await BridgeService.getSysElements();
    if (response) {
      commit("setSysElements", response.data);
    }
  },
};

const mutations = {
  setSysCodes: (state, payload) => { state.sysCodes = payload; },
  setSysComponents: (state, payload) => { state.sysComponents = payload; },
  setSysElements: (state, payload) => { state.sysElements = payload; },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};