const state = {
  token: null,
  user: null,
  isUserLoggedIn: false,
  provinces: null,
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    if (token) {
      state.isUserLoggedIn = true;
    } else {
      state.isUserLoggedIn = false;
    }
  },
  setUser(state, user) {
    state.user = user;
  },
  setProvinces(state, provinces){
    state.provinces = provinces;
  }
};

const getters = {
  get_access: (state) => (module, level_access) => {return state.user && state.user[module] >= level_access;}
};

const actions = {
  setToken({ commit }, token) {
    commit("setToken", token);
  },
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  setProvinces({ commit }, provinces){
    commit("setProvinces", provinces)
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};