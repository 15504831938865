import axios from "axios";
import store from "@/store";

const baseURL = process.env.VUE_APP_ROOT_API;

export default () => {
  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${store.state.authentication.token}`,
      Lang: "la",
      UserId: store.state.authentication.user?.id,
    },
  });
};
