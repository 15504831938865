import BridgeService from "@/services/BridgeService";

const state = () => ({
  bridges: [],
  scenarios: [],
});

const getters = {
  getbridges(state) { return state.bridges; }
};

const actions = {

  async fetchBridges({ commit }) {
    const bridges = (await BridgeService.index()).data;
    commit("setBridges", bridges);
  },
  async fetchScenarios({ commit }) {
    const scenarios = (await BridgeService.indexScenarios()).data.sort((a, b) => Number(a.scenario_number) - Number(b.scenario_number));
    commit("setScenarios", scenarios);
  },
};

const mutations = {
  setBridges: (state, payload) => { state.bridges = payload; },
  setScenarios: (state, payload) => { state.scenarios = payload; }
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};