







































import Vue from "vue";

export default Vue.extend({
  name: "TopBar",
  components: {},
  data: () => ({
    langs: [
      { label: "ລາວ", id: "la" },
      { label: "english", id: "en" },
    ],
  }),
  methods: {
    getImgUrl: (country: string) => {
      var images = require.context("@/assets/flags/", false, /\.png$/);
      return images("./" + country + "64.png");
    },
    logout() {
      this.$router.push("/login");
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setToken", null);
    },
    profilePage(){
      this.$router.push("/profilePage");
    }
  },
});
