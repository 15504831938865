
































import Vue from "vue";

export default Vue.extend({
  name: "NavigationDrawer",
  data: () => ({
    drawer: false,
  }),
  computed: {
    items() {
      return [
        {
          title: this.$t("bms"),
          items: [
            {
              title: this.$t("bridges-list"),
              icon: "mdi-map-marker-radius",
              route: "bridge-management-list",
              module: "module_bridge_management",
              level_access: 1,
            },
            {
              title: this.$t("inspections-list"),
              icon: "mdi-call-split",
              route: "inspections-list",
              module: "module_bridge_management",
              level_access: 1,
            },
            {
              title: this.$t("reporting"),
              icon: "mdi-note",
              route: "reporting",
              module: "module_bridge_management",
              level_access: 1,
            },
            {
              title: this.$t("analysis"),
              icon: "mdi-chart-bar",
              route: "analysis",
              module: "module_analysis",
              level_access: 1,
            },
            {
              title: this.$t("documentation"),
              icon: "mdi-book",
              route: "documentation",
              module: "module_bridge_management",
              level_access: 1,
            },
          ]
        },
        {
          title: this.$t("data-processing"),
          items: [
            {
              title: this.$t("compute"),
              icon: "mdi-calculator-variant",
              route: "data-processing",
              module: "module_data_processing",
              level_access: 1,
            },
            {
              title: this.$t("inspections"),
              icon: "mdi-magnify",
              route: "submissions-inspection",
              module: "module_data_processing",
              level_access: 2,
            },
            {
              title: this.$t("inventory"),
              icon: "mdi-inbox-multiple",
              route: "submissions-inventory",
              module: "module_data_processing",
              level_access: 2,
            },
          ]
        },
        {
          title: this.$t("user-management"),
          items: [
            {
              title: this.$t("users"),
              icon: "mdi-account-multiple",
              route: "user-account",
              module: "module_user_management",
              level_access: 3,
            },
          ],
        },
        {
          title: this.$t("master-data"),
          items: [
            {
              title: this.$t("main-data"),
              icon: "mdi-database",
              route: "static-data",
              module: "module_settings",
              level_access: 3,
            },
            {
              title: this.$t("sys-codes"),
              icon: "mdi-database",
              route: "sys-codes",
              module: "module_settings",
              level_access: 3,
            },
            {
              title: this.$t("inspection-damage"),
              icon: "mdi-database",
              route: "inspection-damage",
              module: "module_settings",
              level_access: 3,
            },
            {
              title: this.$t("damage-factors"),
              icon: "mdi-database",
              route: "damage-factors",
              module: "module_settings",
              level_access: 3,
            },
            {
              title: this.$t("repair-method"),
              icon: "mdi-database",
              route: "repair-method",
              module: "module_settings",
              level_access: 3,
            },
            {
              title: this.$t("lcc-scenario"),
              icon: "mdi-database",
              route: "lcc-scenario",
              module: "module_settings",
              level_access: 3,
            },
            {
              title: this.$t("links"),
              icon: "mdi-database",
              route: "link",
              module: "module_settings",
              level_access: 3,
            },
          ]
        }
      ];
    },
  },
});
