//import API from "../../api/authapi";

const state = () => ({
    userslist: []
});

const getters = {
    // getUsersList(state){ return state.userslist }
};

const actions = {
    // async fetchUsers({ commit }){
    //     const response = await API.getAllUser();
    //     if(response){
    //         commit("setUsers", response);
    //     }else{
    //         console.log("fetch user list error... ")
    //     }
    // },
    // async addNewUser({ commit }, user){
    //     //const response = await API.addUser();
    //     const response = await API.userSignUp(user);
    //     console.log("RESESPONSE-STORE:", response)
    //     if(response){
    //         commit("setNewUser", user);
    //     }else{
    //         console.log("fetch user list error... ")
    //     }
    // },
    // async updateUser({ commit }, id, user){
    //     const response = await API.updateUser(id, user);
    //     if(response){
    //         commit("updateUser", user);
    //     }else{
    //         console.log("fetch user list error... ")
    //     }
    // }
};

const mutations = {
    // setUsers: (state, payload) => { 
    //     state.userslist = payload;
    //     console.log("USER-LIST-MUT:", state.userslist)
    //  },
    //  setNewUser: (state, payload) => { 
    //     state.userslist = payload;
    //     console.log("USER-LIST-MUT:", state.userslist)
    //  },
    //  updateUser: (state, payload) => {
    //      const index = state.userslist.findIndex(user => user._id === payload._id);
    //      if(index !== -1){
    //          state.userslist.splice(index, 1, payload)
    //      }else{
    //          console.log("Error updated user...");
    //      }
    //  }
                
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };