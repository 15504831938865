import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import BridgeService from "@/services/BridgeService";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "list",
    meta: {
      requiresAuth: true,
      module: "none",
    },
  },

  {
    path: "/data-processing",
    name: "data-processing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/DataProcessing.vue"),
    meta: {
      requiresAuth: true,
      module: "module_data_processing",
      access_level: 1,
    },
  },
  {
    path: "/user-account",
    name: "user-account",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/UsersList.vue"),
    meta: {
      requiresAuth: true,
      module: "module_user_management",
      access_level: 3,
    },
  },
  {
    path: "/static-data",
    name: "static-data",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/StaticData.vue"),
    meta: {
      requiresAuth: true,
      module: "module_settings",
      access_level: 3,
    },
  },
  {
    path: "/inspection-damage",
    name: "inspection-damage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/StaticData/InspectionDamage.vue"),
    meta: {
      requiresAuth: true,
      module: "module_settings",
      access_level: 3,
    },
  },
  {
    path: "/link",
    name: "link",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/StaticData/Link.vue"),
    meta: {
      requiresAuth: true,
      module: "module_settings",
      access_level: 3,
    },
  },
  {
    path: "/damage-factors",
    name: "damage-factors",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/StaticData/DamageFactors.vue"),
    meta: {
      requiresAuth: true,
      module: "module_settings",
      access_level: 3,
    },
  },
  {
    path: "/repair-method",
    name: "repair-method",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/StaticData/RepairMethod.vue"),
    meta: {
      requiresAuth: true,
      module: "module_settings",
      access_level: 3,
    },
  },
  {
    path: "/sys-codes",
    name: "sys-codes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/StaticData/SysCodes.vue"),
    meta: {
      requiresAuth: true,
      module: "module_settings",
      access_level: 3,
    },
  },
  {
    path: "/lcc-scenario",
    name: "lcc-scenario",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/StaticData/LccScenario.vue"),
    meta: {
      requiresAuth: true,
      module: "module_settings",
      access_level: 3,
    },
  },
  {
    path: "/inspections-list",
    name: "inspections-list",
    component: () => import(/* webpackChunkName: "bridgeList" */ "@/components/bridgeManagement/bridges/InspectionsList.vue"),
    meta: {
      requiresAuth: true,
      module: "module_bridge_management",
      access_level: 1,
    },
  },
  {
    path: "/bridge-management/",
    component: () => import(/* webpackChunkName: "Bridges" */ "@/components/main/Bridges.vue"),
    children: [
      {
        path: "/map",
        name: "bridge-management-map",
        component: () => import(/* webpackChunkName: "bridgeMapList" */ "@/components/bridgeManagement/bridges/BridgeMap.vue"),
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 1,
        },
      },
      {
        component: () => import(/* webpackChunkName: "bridgeList" */ "@/components/bridgeManagement/bridges/BridgeList.vue"),
        path: "/list",
        name: "bridge-management-list",
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 1,
        },
      },
    ],
  },
  {
    path: "/bridge-management/:id",
    name: "bridge",
    component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/Bridge.vue"),
    children: [


      {
        path: "inventory",
        name: "bridge-inventory",
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeInventory.vue"),
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 1,
        },
      },

      {
        path: "gallery",
        name: "bridge-gallery",
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeInventoryGallery.vue"),
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 1,
        },
      },
      {
        path: "files",
        name: "bridge-files",
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeInventoryFiles.vue"),
        meta: {
          requiresAuth: false,
          module: "module_bridge_management",
          access_level: 1,
        },
      },

      {
        path: "inventory/edit/main",
        name: "bridge-edit-identification-data",
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeEditIdentificationData.vue"),
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 3,
        },
      },

      {
        path: "inventory/edit/details",
        name: "bridge-inventory-edit",
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeInventoryEdit.vue"),
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 2,
        },
      },

      {
        path: "soundness/",
        name: "bridge-soundness",
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeSoundness.vue"),
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 1,
        },
      },

      {
        path: "inspection",
        name: 'bridge-inspection',
        redirect: "inspection/details",
        component: () => import(/* webpackChunkName: "BridgeInspection" */ "@/components/bridgeManagement/bridge/BridgeInspection.vue"),
        children: [
          {
            component: () => import(/* webpackChunkName: "BridgeInspection" */ "@/components/bridgeManagement/bridge/BridgeInspectionDetails.vue"),
            name: "bridge-inspection-current-details",
            path: "details/",
            meta: {
              requiresAuth: true,
              module: "module_bridge_management",
              access_level: 1,
            },
          },
          {
            component: () => import(/* webpackChunkName: "BridgeInspection" */ "@/components/bridgeManagement/bridge/BridgeInspectionDetails.vue"),
            name: "bridge-inspection-details",
            path: "details/:inspectionId",
            meta: {
              requiresAuth: true,
              module: "module_bridge_management",
              access_level: 1,
            },
          },
          {
            path: "history",
            name: "bridge-inspection-history",
            component: () => import(/* webpackChunkName: "BridgeInspectionList" */ "@/components/bridgeManagement/bridge/BridgeInspectionHistory.vue"),
            meta: {
              requiresAuth: true,
              module: "module_bridge_management",
              access_level: 1,
            },
          },
          {
            path: "edit/:inspectionId",
            name: "bridge-inspection-edit",
            component: () => import(/* webpackChunkName: "BridgeInspectionDetailsEdit" */ "@/components/bridgeManagement/bridge/BridgeInspectionDetailsEdit.vue"),
            meta: {
              requiresAuth: true,
              module: "module_bridge_management",
              access_level: 2,
            },
          },
        ]
      },

      {
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeRepairHistory.vue"),
        path: "repair-history",
        name: "bridge-repair-history",
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 1,
        },
      },
      {
        component: () => import(/* webpackChunkName: "Bridge" */ "@/components/bridgeManagement/bridge/BridgeOnMap.vue"),
        path: "bridge-on-map",
        name: "bridge-on-map",
        meta: {
          requiresAuth: true,
          module: "module_bridge_management",
          access_level: 1,
        },
      },
    ],
  },
  {
    path: "/submissions/inspection",
    name: "submissions-inspection",
    component: () => import(/* webpackChunkName: "Submissions" */ "@/components/main/SubmissionsList.vue"),
    meta: {
      requiresAuth: true,
    },
    props: {
      formId: "Bridge%20Inspection%20A",
      submissionType: "inspection"
    }
  },
  {
    path: "/submissions/inventory",
    name: "submissions-inventory",
    component: () => import(/* webpackChunkName: "Submissions" */ "@/components/main/SubmissionsList.vue"),
    meta: {
      requiresAuth: true,
    },
    props: {
      formId: "Bridge_inventory",
      submissionType: "inventory"
    }
  },
  {
    path: "/analysis/",
    name: "analysis",
    component: () => import(/* webpackChunkName: "Analysis" */ "@/components/analysis/TheAnalysisList.vue"),
    meta: {
      requiresAuth: true,
      module: "module_analysis",
      access_level: 1,
    },
  },
  {
    path: "/analysis/details/:id",
    name: "analysis-details",
    component: () => import(/* webpackChunkName: "Analysis" */ "@/components/analysis/TheAnalysisDetails.vue"),
    meta: {
      requiresAuth: true,
      module: "module_analysis",
      access_level: 1,
    },
  },
  {
    component: () => import(/* webpackChunkName: "Analysis" */ "@/components/analysis/TheAnalysisDetails.vue"),
    path: "/analysis/edit/",
    name: "analysis-new",
    meta: {
      requiresAuth: true,
      module: "module_analysis",
      access_level: 2,
    },
  },
  {
    component: () => import(/* webpackChunkName: "Analysis" */ "@/components/main/ProfilePage.vue"),
    path: "/profilePage",
    name: "profile-page",
    meta: {
      requiresAuth: true,
      module: "none",
    },
  },
  {
    component: () => import(/* webpackChunkName: "Home" */ "@/components/main/HomePage.vue"),
    path: "/home",
    name: "home",
    meta: {
      requiresAuth: true,
      module: "none",
    },
  },
  {
    component: () => import(/* webpackChunkName: "Reporting" */ "@/components/bridgeManagement/Reporting.vue"),
    path: "/reporting",
    name: "reporting",
    meta: {
      requiresAuth: true,
      module: "module_reporting",
      access_level: 1,
    },
  },
  {
    component: () => import(/* webpackChunkName: "Documentation" */ "@/components/bridgeManagement/SystemDocumentation.vue"),
    path: "/documentation",
    name: "documentation",
    meta: {
      requiresAuth: true,
      module: "module_bridge_management",
      access_level: 1,
    },
  },
  {
    component: () => import(/* webpackChunkName: "notfoud" */ "@/components/main/NotFound.vue"),
    path: "*",
    name: "notfoud"
  },
  {
    path: "/login",
    name: "login-page",
    component: () => import(/* webpackChunkName: "Login" */ "@/components/main/LoginPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    if ((store.state as any).authentication.isUserLoggedIn) {
      if (to.meta.module !== "none" && (store.state as any).authentication.user[to.meta.module] < to.meta.access_level) {
        (store.state as any).authentication.user[to.meta.module] > 0 ? next("/list") : next("/home");
      }
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
