












import Vue from "vue";
import TopBar from "./components/main/TopBar.vue";
import NavigationDrawer from "./components/main/NavigationDrawer.vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "App",
  components: {
    TopBar,
    NavigationDrawer,
  },
  data: () => ({
    drawer: null,
  }),

  methods: {
    ...mapActions("sysCode", [
      "fetchSysCodes",
      "fetchSysComponents",
      "fetchSysElements"
    ]),
    ...mapActions("adm", [
      "fetchProvinces",
      "fetchDistricts",
    ]),
    ...mapActions("bridge", ["fetchBridges", "fetchScenarios"]),
  },
  mounted() {
    this.fetchSysCodes();
    this.fetchSysComponents();
    this.fetchSysElements();
    this.fetchBridges();
    this.fetchScenarios();
    this.fetchProvinces();
    this.fetchDistricts();

    if(this.$store.state.authentication.user) {
      this.$i18n.locale = this.$store.state.authentication.user.language;
    }
  },
});
